require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`
})

const token = process.env.GATSBY_STORYBLOK_ACCESSTOKEN
const version = process.env.GATSBY_STORYBLOK_VERSION
const protocol = process.env.GATSBY_STORYBLOK_PROTOCOL

module.exports = {
  siteMetadata: {
    siteUrl: `https://www.hasselblad.com`,
  },
  plugins: [
    {
      resolve: "storyblok-source",
      options: {
        accessToken: token,
        homeSlug: "home",
        version: version,
        includeLinks: false,
        https: !(protocol && protocol === 'http')
      },
    },

    `gatsby-plugin-sass`,
    `gatsby-transformer-storyblok-listing`,
    `gatsby-transformer-events`,
    `gatsby-plugin-react-helmet`,
    {
     resolve: `gatsby-plugin-sitemap`,
      options: {
        exclude: [`/not-found/`, `*/not-found/`]
      }
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-W6FNJJN",
  
        // Include GTM in development.
        //
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: true,
  
        // datalayer to be set before GTM is loaded
        // should be an object or a function that is executed in the browser
        //
        // Defaults to null
        defaultDataLayer: { platform: "gatsby" },
  
        // Specify optional GTM environment details.
        //gtmAuth: "YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_AUTH_STRING",
        //gtmPreview: "YOUR_GOOGLE_TAGMANAGER_ENVIRONMENT_PREVIEW_NAME",
        //dataLayerName: "YOUR_DATA_LAYER_NAME",
  
        // Name of the event that is triggered
        // on every Gatsby route change.
        //
        // Defaults to gatsby-route-change
        routeChangeEventName: "gatsby-route-change",
      },
    },
  ],
}
