import React from "react"
import Components from "../components/components.js"
import SbEditable from "storyblok-react"
import config from "../../gatsby-config"

const sbConfigs = config.plugins.filter(item => {
  return item.resolve === "storyblok-source"
})
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}

const loadStoryblokBridge = function(cb) {
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
  script.onload = cb
  document.getElementsByTagName("head")[0].appendChild(script)
}

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = { story: null }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory() {
    let lang = window.storyblok.getParam("_storyblok_lang") === 'default' ? '' : window.storyblok.getParam("_storyblok_lang") + '/';
    window.storyblok.get(
      {
        slug: window.storyblok.getParam("path") || lang + "home",
        version: "draft",
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      data => {
        this.setState({ story: data.story })
      }
    )
  }

  initStoryblokEvents() {
    this.loadStory()

    let sb = window.storyblok

    sb.on(["change", "published"], payload => {
      this.loadStory()
    })

    sb.on("input", payload => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        payload.story.lang = this.state.story.lang;
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return null
    }

    let story = this.state.story
    return (

      <SbEditable content={story.content}>
        <div>
          {React.createElement(Components(story.content.component), {
            key: story.content._uid,
            blok: story.content,
            lang: story.lang,
            path: window.storyblok.getParam("path"),
            name: story.name,
            menu_transparency: story.content.menu_transparency
          })}
        </div>
        { (story.content.languages && story.content.languages.length > 0 && !story.content.languages.includes(story.lang)) &&
        <div className="overlay" style={{ opacity:1, backgroundColor:'#000000' }}>
          <div className="editorial">
            <h1 style={{ color:'#ffffff'}} >CONTENT IS NOT AVAILABLE IN THIS LANGUAGE</h1>
            <p style={{ color:'#ffffff'}}>To have this content available in the current language, check the box for the language under <i>Page &gt; Languages</i></p>
          </div>
        </div>
        }
      </SbEditable>
    )
  }
}

export default StoryblokEntry
